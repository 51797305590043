import React from "react";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

class AddStockToInventory extends React.Component {
  constructor() {
    super();
    this.state = {
      items: [],
      pricelist: [],
      status: ""
    };
  }

  async getqty(index, field, value) {
      try {
    var temp = this.state.items;
    temp[index][field] = value;
    temp[index].quantityonhand = "...";
    this.setState({ items: temp });
    let qty = await Utils.getCurrentQuantityForBrandAndProduct(
      this.state.items[index].brand,
      this.state.items[index].product
    );
    temp[index].quantityonhand = qty;

    this.setState({ items: temp });  } catch (err) {
        console.log(err);
      }
  }
  changeState(index, field, value) {
    try {
    var temp = this.state.items || 0;
    temp[index][field] = value;
    temp[index].costtotal = (
      temp[index].costperunit * temp[index].quantity
    ).toFixed(2);
    this.getqty(index, field, value);

    this.setState({ items: temp });  } catch (err) {
        console.log(err);
      }
  }

  changeStateNumber(index, field, value) {
    try {
    var temp = this.state.items || 0;
    temp[index][field] = value;
    temp[index].costtotal = (
      temp[index].costperunit * temp[index].quantity
    ).toFixed(2);

    this.setState({ items: temp });
  } catch (err) {
      console.log(err);
    }
  }

  optionMapper(input) {
      try {
    return input.map((item, index) => {
      return (
        <option key={index} value={item.label}>
          {item.label}
        </option>
      );
    });
  } catch (err) {
      console.log(err);
    }
  }

  maketablerows(inputitems) {
      try {
    return inputitems.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell style={{ padding: 1, paddingTop: 10 }}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Brand
              </InputLabel>
              <Select
                style={{ width: 300 }}
                native
                value={item.brand}
                onChange={change =>
                  this.changeState(index, "brand", change.target.value)
                }
                input={
                  <OutlinedInput
                    name="Brand"
                    labelWidth={50}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {this.optionMapper(Utils.getUniqueBrands(this.state.pricelist))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 1, paddingTop: 10, minWidth: 200 }}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Product
              </InputLabel>
              <Select
                style={{ width: 300 }}
                native
                value={item.product}
                onChange={change => {
                  try {
                    this.changeState(index, "product", change.target.value);
                  } catch (err) {
                    console.log(err);
                  }
                }}
                input={
                  <OutlinedInput
                    name="Brand"
                    labelWidth={60}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {this.optionMapper(
                  Utils.getUniqueProductByBrand(
                    this.state.pricelist,
                    this.state.items[index].brand
                  )
                )}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              disabled
              id="outlined-name"
              label="Quantity on hand"
              margin="normal"
              value={item.quantityonhand}
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-name"
              label="Qty Adjust (add/subtract)"
              margin="normal"
              value={item.quantity}
              onChange={change =>
                this.changeStateNumber(index, "quantity", change.target.value)
              }
              variant="outlined"
            />
          </TableCell>

          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-disabled"
              label="Cost/unit"
              margin="normal"
              value={item.costperunit}
              onChange={change =>
                this.changeStateNumber(
                  index,
                  "costperunit",
                  Number(change.target.value)
                )
              }
              variant="outlined"
            />
          </TableCell>

          <TableCell style={{ padding: 1 }}>
            <TextField
              disabled
              id="outlined-name"
              label="Total Cost"
              margin="normal"
              value={item.costtotal}
              variant="outlined"
            />
          </TableCell>
        </TableRow>
      );
    });
  } catch (err) {
      console.log(err);
    }
  }

  async saveInvoice(items, date) {
      try {
    var newitems = [];
    items.forEach(function(e) {
      if (e.brand !== "" && e.product !== "") newitems.push(e);
      //e.costtotal = Math.abs(e.costtotal);
      e.quantity = Number(e.quantity);
    });
    this.setState({ status: "Saving..." });

    let saver = await Utils.saveNewStock(items);
    if (saver) {
      this.setState({
        status: "Saved",
        items: []
      });
    }
  } catch (err) {
      console.log(err);
    }
  }

  async updateList() {
      try {
    let list = await Utils.getPriceList();
    this.setState({ pricelist: list });
  } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
      try {
    this.updateList();
  } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <div style={{ margin: 2 }}>
        Note: if you only want to change the stock quantity, Leave the cost/unit
        field blank
        <Table>
          <TableBody style={{ padding: 0 }}>
            {this.maketablerows(this.state.items || null)}
          </TableBody>
        </Table>
        <br />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            var newitems = this.state.items;
            newitems.push({
              brand: "",
              product: "",
              quantity: "",
              costperunit: 0.0,
              costtotal: 0.0,
              quantityonhand: 0
            });
            this.setState({ items: newitems });
          }}
        >
          Add new line
        </Button>
        <br />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.saveInvoice(this.state.items, new Date(Date.now()));
          }}
        >
          Save stock adjustments
        </Button>
        {this.state.status}
      </div>
    );
  }
}

export default AddStockToInventory;
