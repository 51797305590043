import React, { Component } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false
    };
  }
  render() {
    return (
      <div
        style={{
          backgroundColor: "#003057",
          padding: 20,
          color: "#fff",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Drawer
          open={this.state.drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
        >
          <div
            style={{
              width: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}
          >
            {this.props.pages.map((item, index) => (
              <Button
                key={index}
                onClick={() => {
                  if (item.title !== "Logout") {
                    this.props.history.push(item.location);
                    this.setState({ drawerOpen: false });
                  } else this.props.onLogout();
                }}
                
                  color="primary"
              >
                <div>
                  <div>{item.title}</div>
                </div>
              </Button>
            ))}
          </div>
        </Drawer>
        <div>
          <div>
            <Button
              onClick={() => this.setState({ drawerOpen: true })}
              style={{ color: "#fff" }}
            >
              <MenuIcon style={{ marginRight: 20 }} />
            </Button>
            {this.props.pages.filter(
              z => z.location === this.props.history.location.pathname
            ).length > 0
              ? this.props.history.location.pathname === "/"
                ? "Instructions"
                : this.props.pages.filter(
                    z => z.location === this.props.history.location.pathname
                  )[0].title
              : null}
          </div>
          <div>{this.props.name}</div>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
