/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDrhkalanTransactions = /* GraphQL */ `
  query GetDrhkalanTransactions($id: ID!) {
    getDRHKALANTransactions(id: $id) {
      id
      patientname
      date
      icd10
      transactionnumber
      transactiontype
      invoicenumber
      receiptnumber
      medicalaidname
      medicalaidnumber
      totalvalue
      products(limit:30) {
        items {
          id
          brand
          product
          quantity
          icd10
          procedure
          costperunit
          sellingpriceperunit
          costtotal
          sellingpricetotal
        }
        nextToken
      }
    }
  }
`;
export const listDrhkalanTransactionss = /* GraphQL */ `
  query ListDrhkalanTransactionss(
    $filter: ModelDRHKALANTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDRHKALANTransactionss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientname
        date
        icd10
        transactionnumber
        transactiontype
        invoicenumber
        receiptnumber
        medicalaidname
        medicalaidnumber
        totalvalue
        products(limit:50) {
          items {
            brand
            product
            quantity
            icd10
            procedure
            costperunit
            sellingpriceperunit
            costtotal
            sellingpricetotal
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDrhkalanProducts = /* GraphQL */ `
  query GetDrhkalanProducts($id: ID!) {
    getDRHKALANProducts(id: $id) {
      id
      brand
      product
      quantity
      icd10
      procedure
      costperunit
      sellingpriceperunit
      costtotal
      sellingpricetotal
      transactions {
        id
        patientname
        date
        icd10
        transactionnumber
        transactiontype
        invoicenumber
        receiptnumber
        medicalaidname
        medicalaidnumber
        totalvalue
        products {
          nextToken
        }
      }
    }
  }
`;
export const listDrhkalanProductss = /* GraphQL */ `
  query ListDrhkalanProductss(
    $filter: ModelDRHKALANProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDRHKALANProductss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brand
        product
        quantity
        icd10
        procedure
        costperunit
        sellingpriceperunit
        costtotal
        sellingpricetotal
        transactions {
          id
          patientname
          date
          icd10
          transactionnumber
          transactiontype
          invoicenumber
          receiptnumber
          medicalaidname
          medicalaidnumber
          totalvalue
        }
      }
      nextToken
    }
  }
`;
export const getDrhkalanPriceList = /* GraphQL */ `
  query GetDrhkalanPriceList($id: ID!) {
    getDRHKALANPriceList(id: $id) {
      id
      brand
      product
      costperunit
      sellingpriceperunit
    }
  }
`;
export const listDrhkalanPriceLists = /* GraphQL */ `
  query ListDrhkalanPriceLists(
    $filter: ModelDRHKALANPriceListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDRHKALANPriceLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brand
        product
        costperunit
        sellingpriceperunit
      }
      nextToken
    }
  }
`;
export const getDrhkalanTransactionnumber = /* GraphQL */ `
  query GetDrhkalanTransactionnumber($id: ID!) {
    getDRHKALANTransactionnumber(id: $id) {
      id
      transactionnumber
    }
  }
`;
export const listDrhkalanTransactionnumbers = /* GraphQL */ `
  query ListDrhkalanTransactionnumbers(
    $filter: ModelDRHKALANTransactionnumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDRHKALANTransactionnumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionnumber
      }
      nextToken
    }
  }
`;
