import React from "react";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PivotTableUI from "react-pivottable/PivotTableUI";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import TableRow from "@material-ui/core/TableRow";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      startdate: "2023-12-01",
      enddate: "2024-12-31",
      pivotState: {
        data: [],

      }
    };
  }

  async getSales() {
    this.setState({ status: "Collecting data..." });
    var transactionIds = await Utils.getAllTransactionNumbers(
      this.state.startdate,
      this.state.enddate
    );
    console.log(transactionIds)

    let data=[]
    transactionIds.forEach(a=>{
      a.products.items.forEach(b=>{
        let item = b
        item.date=a.date
        item.icd10=a.icd10
        item.invoicenumber=a.invoicenumber
        item.patientname=a.patientname
        item.receiptnumber=a.receiptnumber
        item.totalvalue=a.totalvalue
        item.transactionnumber=a.transactionnumber
        item.transactiontype=a.transactiontype
item.YearMonth = a.date.substring(0, 7);

        data.push(
            item
        )
      })
    })
    this.setState({
      status: "Complete",
      pivotState: {
        data: data,

      }
    });
  }

  async getALLSales() {
    this.setState({ status: "Collecting data..." });
    var transactionIds = await Utils.getAllTransactionNumbers(
      "2012-01-01",
      (new Date(Date.now())).toISOString().substr(0,10)
    );
    let data=[]
    transactionIds.forEach(a=>{
      a.products.items.forEach(b=>{
        let item = b
        item.date=a.date
        item.icd10=a.icd10
        item.invoicenumber=a.invoicenumber
        item.patientname=a.patientname
        item.receiptnumber=a.receiptnumber
        item.totalvalue=a.totalvalue
        item.transactionnumber=a.transactionnumber
        item.transactiontype=a.transactiontype
item.YearMonth = a.date.substring(0, 7);

        data.push(
            item
        )
      })
    })

    this.setState({
      status: "Complete",
      pivotState: {
        data: data,

      }
    });
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <TextField
          id="date"
          label="Start"
          type="date"
          value={this.state.startdate}
          onChange={change => this.setState({ startdate: change.target.value })}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          id="date"
          label="End"
          type="date"
          value={this.state.enddate}
          onChange={change => this.setState({ enddate: change.target.value })}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            //this.getTransactionCN(item.id);
            this.getSales();
          }}
        >
          Collect data for these dates
        </Button>
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            //this.getTransactionCN(item.id);
            this.getALLSales();
          }}
        >
          Collect all data (very slow)
        </Button>
        {this.state.status}
        <br />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({
              status: "Complete",
              pivotState: {
                data: this.state.pivotState.data,
                rows: ["brand", "product"],
                cols: ["transactiontype", "YearMonth"],
                vals:["quantity"],
                aggregatorName: "Sum"
              }
            });
          }}
        >
          Quantity sold
        </Button>
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({
              status: "Complete",
              pivotState: {
                data: this.state.pivotState.data,
                rows: ["brand", "product"],
                cols: ["transactiontype", "YearMonth"],
                vals:["sellingpricetotal"],
                aggregatorName: "Sum"
              }
            });
          }}
        >
          Sales value
        </Button>
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({
              status: "Complete",
              pivotState: {
                data: this.state.pivotState.data,
                rows: ["brand", "product"],
                cols: ["transactiontype", "YearMonth"],
                vals:["costtotal"],
                aggregatorName: "Sum"
              }
            });
          }}
        >
          Cost
        </Button>
        <br />
        <div style={{ marginTop: 10 }}>
          <PivotTableUI
            onChange={s => {

              this.setState({ pivotState: s });
            }}
            renderers={Object.assign({}, TableRenderers, {})}
            {...this.state.pivotState}
          />
        </div>
      </div>
    );
  }
}
export default Reports;
