import React from "react";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

class UpdatePriceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      pricelist: [],
      status: ""
    };
  }

  changeState(index, field, value) {
    var temp = this.state.items || 0;
    temp[index][field] = value;
    var prices = Utils.getPrices(
      this.state.pricelist,
      this.state.items[index].brand,
      this.state.items[index].product
    );
    temp[index].sellingpriceperunit = prices.sellingpriceperunit;
    temp[index].costperunit = prices.costperunit;
    this.setState({ items: temp });
  }

  changeStateValue(index, field, value) {
    var temp = this.state.items || 0;
    temp[index][field] = value;
    temp[index][field] = value;
    this.setState({ items: temp });
  }

  optionMapper(input) {
    return input.map((item, index) => {
      return (
        <option key={index} value={item.label}>
          {item.label}
        </option>
      );
    });
  }

  maketablerows(inputitems) {
    return inputitems.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell style={{ padding: 1, paddingTop: 10 }}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Brand
              </InputLabel>
              <Select
                style={{ width: 300 }}
                native
                value={item.brand}
                onChange={change =>
                  this.changeState(index, "brand", change.target.value)
                }
                input={
                  <OutlinedInput
                    name="Brand"
                    labelWidth={50}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {this.optionMapper(Utils.getUniqueBrands(this.state.pricelist))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 1, paddingTop: 10, minWidth: 200 }}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Product
              </InputLabel>
              <Select
                style={{ width: 300 }}
                native
                value={item.product}
                onChange={change =>
                  this.changeState(index, "product", change.target.value)
                }
                input={
                  <OutlinedInput
                    name="Brand"
                    labelWidth={60}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {this.optionMapper(
                  Utils.getUniqueProductByBrand(
                    this.state.pricelist,
                    this.state.items[index].brand
                  )
                )}
              </Select>
            </FormControl>
          </TableCell>

          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-disabled"
              label="Cost/unit"
              margin="normal"
              value={item.costperunit}
              onChange={change =>
                this.changeStateValue(
                  index,
                  "costperunit",
                  Number(change.target.value)
                )
              }
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-disabled"
              label="Selling price/unit"
              margin="normal"
              value={item.sellingpriceperunit}
              onChange={change =>
                this.changeStateValue(
                  index,
                  "sellingpriceperunit",
                  Number(change.target.value)
                )
              }
              variant="outlined"
            />
          </TableCell>
        </TableRow>
      );
    });
  }

  async saveInvoice(items) {
    var pl = this.state.pricelist
    items.forEach(function(a){
      pl.forEach(function(b){
        if (a.brand === b.brand && a.product === b.product) a.id = b.id
      })
    })
    let promises = items.map(Utils.updatePL);
    let results = await Promise.all(promises);
    //console.log(results);
    // var newitems =[]
    // items.forEach(function(e){
    //   if (e.brand!=="" && e.product !=="") newitems.push(e)
    //   e.costtotal = Math.abs(e.costtotal)
    //   e.quantity = Number(e.quantity)
    // })
    // this.setState({ status: "Saving..." });
    //
    // let saver = await Utils.saveNewStock(items);
    if (results) {
      this.setState({
        status: "Saved",
        items: []
      });
    }
  }

  async updateList() {
    let list = await Utils.getPriceList();
    this.setState({ pricelist: list });
  }

  componentDidMount() {
    this.updateList();
  }
  render() {
    return (
      <div style={{ margin: 2 }}>
        Note: if you only want to change the stock quantity, Leave the cost/unit
        field blank
        <Table>
          <TableBody style={{ padding: 0 }}>
            {this.maketablerows(this.state.items || null)}
          </TableBody>
        </Table>
        <br />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            var newitems = this.state.items;
            newitems.push({
              brand: "",
              product: "",
              costperunit: 0.0,
              sellingpriceperunit: 0.0
            });
            this.setState({ items: newitems });
          }}
        >
          Add new line
        </Button>
        <br />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.saveInvoice(this.state.items);
          }}
        >
          Save stock adjustments
        </Button>
        {this.state.status}
      </div>
    );
  }
}

export default UpdatePriceList;
