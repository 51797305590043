import React from "react";
class Instructions extends React.Component {
  render() {
    return (
      <div style={{ margin: 10 }}>
        <h3>If you want to add more stock of an existing item</h3>
        Click on "Add new stock to inventory". Select the item and add the
        quantity and the cost per unit
        <br />
        If this is a new item, you never sold before (it does not appear in the
        drop down lists), then you have to follow the NEW stock instruction
        below.
        <br />
        Also, you must go to "change price list" and update the latest cost per
        unit and its selling price
        <br />
        If there is a mistake and the stock added was too little or too much,
        redo this process with the difference.
        <h3>If you want to add NEW stock of a NEW item</h3>
        Click on "Add new product to pricelist". This will create a new item in
        the pricelist so that when you add the stock, the item will appear in
        the drop down.
        <br />
        Then go to "Add new stock to inventory", select the item and add the
        quantity and the cost prices.
        <h3>If you want to see the pricelist</h3>
        Click "View product prices" and the list will appear
        <h3>If you want to see the quantity on hand of a product</h3>
        Click "View product prices" and the list will appear. Wait a while. It
        is getting all transactions and calculating the total.
        <h3>If you want to adjust the selling or the cost prices </h3>
        Click "Change price list". Select the item and change the prices. These
        prices will be used in all the new invoices and cost records that will
        be made
        <h3>When you make a sale</h3>
        <ul>
          <li>
            SALE: Click "create invoice" and complete the form. You can click
            "preview invoice" to see a sample. The transaction is only saved
            once you click "save invoice".
          </li>
          <ul>
            <li>
              It take a few seconds for the quatitiy on hand to appear. If you
              dont, have enough stock, check the physical stock and update the
              system if the system doesnt match reality. You will still be able
              to invoice more quantity than what is displayed. Your duty to keep
              it updated
            </li>
            <li>
              If you are giving a discount to the customer, you can edit the
              "selling price / unit" field to put in the new price you want to
              give the customer.
            </li>
          </ul>
          <li>
            SALE: You can either give the sample invoice to the customer or if
            you have already saved it, generate a new one from the "Payments"
            button.
          </li>
          <li>
            SALE: Once you save the invoice, and got the PDF, you give the PDF
            to the customer as a 'request to pay' the amount. Once the customer
            pays, you can either generate a receipt or generate a credit not if
            they want to return items.
          </li>
          <li>
            SALE: If the customer is happy with the invoice, they pay you, you
            will then go to "payments", click "paid, get receipt". The receipt
            number will be generated and the button will change to "generate
            receipt". If you click it, you will be taken to a page where you can
            download the pdf of the receipt.
          </li>
          <li>
            SALE: If the customer is not happy or there is a mistake, and you
            need to ADD items, just make a new invoice.
          </li>
        </ul>
        <h3>When there is returned stock from customers</h3>
        <ul>
          <li>
            RETURNS: If the customer is not happy or there is a mistake with the
            invoice, and you need to REDUCE items, click on "payments", find
            your invoice, click "make new credit note" & fill in the form.
          </li>
          <ul>
            <li>
              If the customer wants to return 2 items, put a 2 in the credit
              quantity field. The value is automatically calculated.
            </li>
            <li>
              Get the document by clicking "preview credit note". Save the PDF,
              give it to the customer and if everything is correct, click "save
              credit note". The returned stock is automatically put back into
              the inventory so there is no need to perform a stock adjustment.
            </li>
            <li>
              The PDF for credit notes button will not reappear in the
              "payments" page, so if you lose this credit note, you will have to
              generate a new one but DONT save it.
            </li>
            <li>
              When you click payments, your saved credit notes will appear with
              the invoices and its buttons will be disabled.
            </li>
          </ul>
        </ul>
        <h3>If you want to see report of sales</h3>
        <ul>
          <li>
            Click "reports". if you want the last 4 months of sales, change the
            start and end dates that appear on the screen. Then click "collect
            data for these dates". The system will find all the transactions
            between (and including) those dates. You will see the words
            "collecting data" and then when its done "complete".
          </li>
          <li>
            Once the data has been collected, you can now see the results by
            clicking "quantity sold". Negative items are stocks leaving your
            inventory, and positive items are stock being added.
          </li>
          <ul>
            <li>
              FILTERING: If you want to remove "stock adjustments", click on the
              down arrow next to the words "transactiontype". You will see a
              list "Sale", "StockAdjustment" and "Credit note". Click on
              "StockAdjustment" and it will be de-selected, and the table will
              change to remove it.
            </li>

          </ul>
          <li>You can click "sales value" to look at the revenue</li>
          <li>You can click "cost" to look at the costs. </li>
          <ul >
          <li>
            NOTE: There is no change of sign here. So costs of sales appear as
            positive values and increases in stock dues to "StockAdjustment"s
            also appear as positive
          </li>
          </ul>
          <li>If you want to see the stock on hand, it can be done here but will be faster in the "view product prices" page. If you use this "reports" page, you will need to collect all data, then click "quantity sold". the last column is the final quantity. </li>
          <li>If you click "collect all data", it will be slow to download the entire database. You browser might show an error, ignore it, just wait. If you're waiting longer than 5 mintes, close the window and try again.</li>
        </ul>
      </div>
    );
  }
}
export default Instructions;
