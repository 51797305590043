import React from "react";
import Utils from "./utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

export default class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      hide: false,
      filterval: -999999999,
      status: "Getting quantities. Please wait...",
      totalvalue: 0
    };
  }

  async updateList() {
    let list = await Utils.getPriceList();
    this.setState({ items: list });
  }

  async getCurrentQuantity() {
    var qty = await Utils.getCurrentQuantity();
    var matchedItems = await Utils.matchItems(this.state.items, qty);
    var total = 0;
    matchedItems.forEach(a => {
      a.totalcosts = (a.quantity * a.costperunit).toFixed(2);
      total += parseFloat(a.totalcosts);
    });
    this.setState({
      items: matchedItems,
      status: "",
      totalvalue: total.toFixed(2)
    });
  }

  async componentDidMount() {
    this.updateList();
    this.getCurrentQuantity();
  }

  maketablerows(inputitems) {
    return inputitems.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell>{item.brand}</TableCell>
          <TableCell>{item.product}</TableCell>
          <TableCell align="right">
            {Utils.formatTextToCurrency(item.costperunit)}
          </TableCell>
          <TableCell align="right">
            {Utils.formatTextToCurrency(item.sellingpriceperunit)}
          </TableCell>
          <TableCell align="right">{item.quantity}</TableCell>
          <TableCell align="right">
            {Utils.formatTextToCurrency(item.totalcosts || 0)}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <div style={{ marginTop: 10 }}>
        {this.state.status}
        <div>
          <Checkbox
            checked={this.state.hide}
            onChange={change => {
              let filterval = -999999999;
              if (!this.state.hide) filterval = 0;
              this.setState({ hide: !this.state.hide, filterval });
            }}
          />
          Hide zero stock
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Brand</TableCell>
              <TableCell>Product</TableCell>
              <TableCell align="right">Cost per unit</TableCell>
              <TableCell align="right">Selling price per unit</TableCell>
              <TableCell align="right">Quantity on hand</TableCell>
              <TableCell align="right">Total cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.maketablerows(
              this.state.items.filter(z => z.quantity > this.state.filterval) ||
                null
            )}
          </TableBody>
        </Table>
        Total stock on hand {Utils.formatTextToCurrency(this.state.totalvalue)}
      </div>
    );
  }
}
