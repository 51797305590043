import React from "react";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink
} from "@react-pdf/renderer";

class CreateInvoice extends React.Component {
  constructor() {
    super();
    this.state = {
      patientname: "",
      date: "",
      icd10: "",
      transactionnumber: 0,
      transactiontype: "Sale",
      invoicenumber: 0,
      items: [],
      pricelist: [],
      medicalaidname: "",
      medicalaidnumber: "",
      status: ""
    };
  }

  makedoc(
    items,
    patient,
    icd,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber
  ) {
    const TextArrayMapper = () => {
      return items.map((item, index) => {
        return (
          <View
            key={index}
            style={{ flexDirection: "row", flexWrap: "wrap", marginBottom: 10 }}
          >
            <View
              style={{
                flexDirection: "column",
                maxWidth: "30%",
                minWidth: "30%"
              }}
            >
              <Text style={{ fontSize: 12 }}>{item.brand}</Text>
              <Text style={{ fontSize: 10 }}>{item.product}</Text>
            </View>
            <Text style={{ fontSize: 12, maxWidth: "10%", minWidth: "10%" }}>
              {item.icd10}
            </Text>
            <Text style={{ fontSize: 10, maxWidth: "15%", minWidth: "15%" }}>
              {item.procedure}
            </Text>
            <Text
              style={{
                fontSize: 12,
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "right"
              }}
            >
              {Number(item.quantity)}
            </Text>

            <Text
              style={{
                fontSize: 12,
                maxWidth: "15%",
                minWidth: "15%",
                textAlign: "right"
              }}
            >
              {Utils.formatTextToCurrency(item.sellingpriceperunit)}
            </Text>
            <Text
              style={{
                fontSize: 12,
                maxWidth: "15%",
                minWidth: "15%",
                textAlign: "right"
              }}
            >
              {Utils.formatTextToCurrency(item.sellingpricetotal)}
            </Text>
          </View>
        );
      });
    };

    return (
      <Document>
        <Page
          size="A4"
          style={{
            flexDirection: "row",
            backgroundColor: "#FFF"
          }}
        >
          <View
            style={{
              margin: 10,
              padding: 10,
              width: "100%"
            }}
          >
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 10
              }}
            >
              <Text style={{ fontSize: 14 }}>DR HEMA KALAN INC</Text>
              <Text style={{ fontSize: 14 }}>VAT: 4600293072</Text>
            </View>
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 10
              }}
            >
              <Text style={{ fontSize: 14 }}>MBBCH (Wits)</Text>
              <Text style={{ fontSize: 14 }}>PR: 012 6012 HSA : 40101</Text>
              <Text style={{ fontSize: 14 }}>
                99 Lenchen Avenue North, Centurion, 0157
              </Text>
              <Text style={{ fontSize: 14 }}>
                Tel: 0126632732 Email: info@drhemakalan.com
              </Text>
            </View>
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 30
              }}
            >
              <Text>INVOICE</Text>
            </View>
            <View
              style={{
                marginBottom: 10
              }}
            >
              <Text style={{ fontSize: 14 }}>Date: {date}</Text>
              <Text style={{ fontSize: 14 }}>Patient: {patient}</Text>
              <Text style={{ fontSize: 14 }}>
                Medical Aid Name and Number: {medicalaidname} {medicalaidnumber}
              </Text>
              <Text style={{ fontSize: 14 }}>ICD 10: {icd}</Text>
              <Text style={{ fontSize: 14 }}>
                Invoice number: {invoicenumber}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: 5
              }}
            >
              <Text style={{ fontSize: 12, maxWidth: "30%", minWidth: "30%" }}>
                Product Description
              </Text>
              <Text style={{ fontSize: 12, maxWidth: "10%", minWidth: "10%" }}>
                ICD10
              </Text>
              <Text style={{ fontSize: 12, maxWidth: "15%", minWidth: "15%" }}>
                Procedure
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "10%",
                  minWidth: "10%",
                  textAlign: "right"
                }}
              >
                Quantity
              </Text>

              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "15%",
                  minWidth: "15%",
                  textAlign: "right"
                }}
              >
                Price/Unit
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "15%",
                  minWidth: "15%",
                  textAlign: "right"
                }}
              >
                Total
              </Text>
            </View>
            <TextArrayMapper />
              <Text
                style={{ fontSize: 14, textAlign: "right", marginRight: "5%" }}
              >
                VAT:{" "}
                {Utils.formatTextToCurrency((Utils.calcTotal(items)*(1-(1/1.15))).toFixed(2))}
              </Text>
            <Text
              style={{ fontSize: 14, textAlign: "right", marginRight: "5%" }}
            >
              Total:{" "}
              {Utils.formatTextToCurrency(Utils.calcTotal(items).toFixed(2))}
            </Text>
          </View>
        </Page>
      </Document>
    );
  }

  async updateList() {
    try {
      // console.log(await Utils.gettransactionnumber())
      // console.log(await Utils.updatetransactionnumber())
      let list = await Utils.getPriceList();
      let invoicenumber = await Utils.gettransactionnumber();
      // console.log(invoicenumber)
      this.setState({ pricelist: list, invoicenumber: invoicenumber + 1 });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.updateList();
  }

  async getqty(index, field, value) {
      var temp = this.state.items;
      temp[index][field] = value;
      let qty = await Utils.getCurrentQuantityForBrandAndProduct(
        this.state.items[index].brand,
        this.state.items[index].product
      );
      temp[index].quantityonhand = qty;

      this.setState({ items: temp });
  }

  changeStateDropDown(index, field, value) {
      var temp = this.state.items;
      temp[index][field] = value;
      var prices = Utils.getPrices(
        this.state.pricelist,
        this.state.items[index].brand,
        this.state.items[index].product
      );
      temp[index].sellingpriceperunit = prices.sellingpriceperunit;
      temp[index].costperunit = prices.costperunit;
      if (field === "product") this.getqty(index, field, value);

      this.setState({ items: temp });
  }

  changeStateNoPrice(index, field, value) {
      var temp = this.state.items;
      temp[index][field] = value;
      temp[index].costtotal = (
        temp[index].costperunit * temp[index].quantity
      ).toFixed(2);
      temp[index].sellingpricetotal = (
        temp[index].sellingpriceperunit * temp[index].quantity
      ).toFixed(2);
      temp[index].sellingpricetotal = (
        temp[index].sellingpriceperunit * temp[index].quantity
      ).toFixed(2);
      this.setState({ items: temp });
  }

  optionMapper(input) {
      return input.map((item, index) => {
        return (
          <option key={index} value={item.label}>
            {item.label}
          </option>
        );
      });
  }

  maketablerows(inputitems) {
    return inputitems.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell style={{ padding: 1, paddingTop: 10 }}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Brand
              </InputLabel>
              <Select
                style={{ width: 200 }}
                native
                value={item.brand}
                onChange={change =>
                  this.changeStateDropDown(index, "brand", change.target.value)
                }
                input={
                  <OutlinedInput
                    name="Brand"
                    labelWidth={50}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {this.optionMapper(Utils.getUniqueBrands(this.state.pricelist))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 1, paddingTop: 10, minWidth: 200 }}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Product
              </InputLabel>
              <Select
                style={{ width: 200 }}
                native
                value={item.product}
                onChange={change => {
                  this.changeStateDropDown(
                    index,
                    "product",
                    change.target.value
                  );
                }}
                input={
                  <OutlinedInput
                    name="Brand"
                    labelWidth={60}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {this.optionMapper(
                  Utils.getUniqueProductByBrand(
                    this.state.pricelist,
                    this.state.items[index].brand
                  )
                )}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-name"
              label="Quantity"
              margin="normal"
              value={item.quantity}
              onChange={change =>
                this.changeStateNoPrice(
                  index,
                  "quantity",
                  Number(change.target.value)
                )
              }
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              disabled
              id="outlined-disabled"
              label="On hand"
              margin="normal"
              value={item.quantityonhand}
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-name"
              label="ICD 10"
              margin="normal"
              value={item.icd10}
              onChange={change =>
                this.changeStateNoPrice(index, "icd10", change.target.value)
              }
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-name"
              label="Procedure"
              margin="normal"
              value={item.procedure}
              onChange={change =>
                this.changeStateNoPrice(index, "procedure", change.target.value)
              }
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              disabled
              id="outlined-disabled"
              label="Cost/unit"
              margin="normal"
              value={item.costperunit}
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              id="outlined-name"
              label="Selling price/unit (editable)"
              margin="normal"
              value={item.sellingpriceperunit}
              onChange={change =>
                this.changeStateNoPrice(
                  index,
                  "sellingpriceperunit",
                  change.target.value
                )
              }
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              disabled
              id="outlined-name"
              label="Total Cost"
              margin="normal"
              value={item.costtotal}
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 1 }}>
            <TextField
              disabled
              id="outlined-name"
              label="Total Price"
              margin="normal"
              value={item.sellingpricetotal}
              variant="outlined"
            />
          </TableCell>
        </TableRow>
      );
    });
  }

  async saveInvoice(
    items,
    patientname,
    icd10,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber
  ) {

    this.setState({ status: "Saving..." });
    //console.log(medicalaidname);
    let saver = await Utils.saveInvoice(
      items,
      patientname,
      icd10,
      medicalaidname,
      medicalaidnumber,
      date,
      invoicenumber
    );
    if (saver) {
      // var invnumber = await Utils.gettransactionnumber();
      this.setState({
        status: "Saved",
        patientname: "",
        date: "",
        icd10: "",
        transactionnumber: 0,
        transactiontype: "Sale",
        invoicenumber: invoicenumber + 1,
        items: [],
        medicalaidname: "",
        medicalaidnumber: ""
      });
    }
  }

  render() {
    return (
      <div style={{ margin: 2 }}>
        <TextField
          style={{ width: 300 }}
          id="outlined-name"
          label="Patient name"
          margin="normal"
          value={this.state.patientname}
          onChange={change =>
            this.setState({ patientname: change.target.value })
          }
          variant="outlined"
        />
        <TextField
          style={{ width: 300 }}
          id="outlined-name"
          label="ICD 10"
          margin="normal"
          value={this.state.icd10}
          onChange={change => this.setState({ icd10: change.target.value })}
          variant="outlined"
        />
        <TextField
          style={{ width: 300 }}
          id="outlined-name"
          label="Medical aid name"
          margin="normal"
          value={this.state.medicalaidname}
          onChange={change =>
            this.setState({ medicalaidname: change.target.value })
          }
          variant="outlined"
        />
        <TextField
          style={{ width: 300 }}
          id="outlined-name"
          label="Medical aid number"
          margin="normal"
          value={this.state.medicalaidnumber}
          onChange={change =>
            this.setState({ medicalaidnumber: change.target.value })
          }
          variant="outlined"
        />
        <br />
        <Table>
          <TableBody style={{ padding: 0 }}>
            {this.maketablerows(this.state.items || null)}
          </TableBody>
        </Table>
        <br />
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            var newitems = this.state.items;
            newitems.push({
              brand: "",
              product: "",
              quantity: 0,
              icd10: "",
              procedure: "",
              costperunit: 0,
              sellingpriceperunit: 0,
              costtotal: 0,
              sellingpricetotal: 0,
              quantityonhand: 0
            });
            this.setState({ items: newitems });
          }}
        >
          Add new line
        </Button>
        <br />
        <Button style={{ margin: 2 }} variant="contained" color="primary">
          <PDFDownloadLink
            style={{ color: "#fff", textDecorationLine: "none" }}
            document={this.makedoc(
              this.state.items,
              this.state.patientname,
              this.state.icd10,
              this.state.medicalaidname,
              this.state.medicalaidnumber,
              this.state.date,
              this.state.invoicenumber
            )}
            fileName="invoice.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Preview invoice"
            }
          </PDFDownloadLink>
        </Button>
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.saveInvoice(
              this.state.items,
              this.state.patientname,
              this.state.icd10,
              this.state.medicalaidname,
              this.state.medicalaidnumber,
              new Date(Date.now()).toDateString(),
              this.state.invoicenumber
            );
          }}
        >
          Save invoice
        </Button>

        {this.state.status}
      </div>
    );
  }
}

export default CreateInvoice;
