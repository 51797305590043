import React from "react";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import {
  Page,Font,
  Text,
  View,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
//
// // import OutlinedInput from "@material-ui/core/OutlinedInput";
// import TableRow from "@material-ui/core/TableRow";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "../Roboto-Regular.ttf",
    },
    {
      src: "../Roboto-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
class IndividualTransaction extends React.Component {
  makedocI(
    items,
    patient,
    icd,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber,
    totalvalue
  ) {
    // console.log(items);
    const TextArrayMapper = () => {
      return items.map((item, index) => {
        return (
          <View
            key={index}
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginBottom: 10,
              borderTopWidth: "1",
              marginRight:"5%"
            }}
          >
            <View
              style={{
                flexDirection: "column",
                width: "30%",

              }}
            >
              <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                {item.brand}
              </Text>
              <Text style={{ fontSize: 10 }}>{item.product}</Text>
            </View>
            <Text style={{ fontSize: 12,   width: "10%",}}>
              {item.icd10}
            </Text>
            <Text style={{ fontSize: 10,   width: "15%", }}>
              {item.procedure}
            </Text>
            <Text
              style={{
                fontSize: 12,
                  width: "12%",
                textAlign: "right",
              }}
            >
              {Number(item.quantity)}
            </Text>
            <Text
              style={{
                fontSize: 12,
                  width: "16%",
                textAlign: "right",
              }}
            >
              {Utils.formatTextToCurrency(item.sellingpriceperunit)}
            </Text>
            <Text
              style={{
                fontSize: 12,
                width: "16%",
                textAlign: "right",
              }}
            >
              {Utils.formatTextToCurrency(item.sellingpricetotal)}
            </Text>
          </View>
        );
      });
    };

    return (
      <Document>
        <Page
          size="A4"
          style={{
              fontFamily: "Open Sans",
            flexDirection: "row",
            backgroundColor: "#FFF",
          }}
        >
          <View
            style={{
              margin: 10,
              padding: 10,
              width: "100%",
            }}
          >
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 14 }}>DR HEMA KALAN INC</Text>
              <Text style={{ fontSize: 12 }}>VAT: 4600293072</Text>
            </View>
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 12 }}>MBBCH (Wits)</Text>
              <Text style={{ fontSize: 12 }}>PR: 012 6012 HSA : 40101</Text>
              <Text style={{ fontSize: 12 }}>
                99 Lenchen Avenue North, Centurion, 0157
              </Text>
              <Text style={{ fontSize: 12 }}>
                Tel: 0126632732 Email: info@drhemakalan.com
              </Text>
            </View>
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <Text>RECEIPT</Text>
            </View>
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, width: "200" }}
                >
                  Date:
                </Text>
                <Text style={{ fontSize: 12 }}>{date}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, width: "200" }}
                >
                  Patient:
                </Text>
                <Text style={{ fontSize: 12 }}>{patient}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, width: "200" }}
                >
                  Medical Aid Name and Number:
                </Text>
                <Text style={{ fontSize: 12 }}>
                  {medicalaidname} {medicalaidnumber}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, width: "200" }}
                >
                  ICD 10:
                </Text>
                <Text style={{ fontSize: 12 }}>{icd}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 12, width: "200" }}
                >
                  Invoice number:
                </Text>
                <Text style={{ fontSize: 12 }}>{invoicenumber}</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: 5,
              }}
            >
              <Text style={{ fontSize: 12, maxWidth: "30%", minWidth: "30%" }}>
                Product Description
              </Text>
              <Text style={{ fontSize: 12, maxWidth: "10%", minWidth: "10%" }}>
                ICD10
              </Text>
              <Text style={{ fontSize: 12, maxWidth: "15%", minWidth: "15%" }}>
                Procedure
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "10%",
                  minWidth: "10%",
                  textAlign: "right",
                }}
              >
                Quantity
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "15%",
                  minWidth: "15%",
                  textAlign: "right",
                }}
              >
                Price/Unit
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "15%",
                  minWidth: "15%",
                  textAlign: "right",
                }}
              >
                Total
              </Text>
            </View>
            <TextArrayMapper />
            <Text
              style={{ fontSize: 14, textAlign: "right", marginRight: "5%" }}
            >
              VAT:{" "}
              {Utils.formatTextToCurrency(
                (Utils.calcTotal(items) * (1 - 1 / 1.15)).toFixed(2)
              )}
            </Text>
            <Text
              style={{ fontSize: 14, textAlign: "right", marginRight: "5%" }}
            >
              Total:{" "}
              {Utils.formatTextToCurrency(Utils.calcTotal(items).toFixed(2))}
            </Text>

          </View>
        </Page>
      </Document>
    );
  }

  constructor(props) {
    super(props);
    this.state = props.location.state.trx;
    // this.state = {
    //   docnumber: props.trx.invoicenumber,
    //   transaction: props.trx.transactionnumber,
    //   status: "",
    //   items: props.trx.products.items,
    //   patientname: props.trx.patientname,
    //   icd10: props.trx.icd10,
    //   medicalaidname: props.trx.medicalaidname,
    //   medicalaidnumber: props.trx.medicalaidnumber,
    //   date: new Date(props.trx.date).toDateString(),
    //   invoicenumber: props.trx.invoicenumber,
    //   receiptnumber: props.trx.receiptnumber,
    //   disableinvoice: true,
    //   disablereceipt: true,
    //   totalvalue: props.trx.totalvalue
    // };
  }

  add2hrs(inputdate) {
    inputdate = new Date(inputdate);
    inputdate.setHours(inputdate.getHours() + 2);
    return inputdate;
  }

  render() {
    return (
      <div style={{ margin: 2 }}>
        <TextField
          id="date"
          label="Override Date"
          type="date"
          value={this.add2hrs(this.state.date).toISOString().substring(0, 10)}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(change) => {
            var newdate = this.add2hrs(
              new Date(change.target.value)
            ).toDateString();

            this.setState({ date: newdate });
          }}
        />
        <Button style={{ margin: 2 }} variant="contained" color="primary">
          <PDFDownloadLink
            style={{ color: "#fff", textDecorationLine: "none" }}
            document={this.makedocI(
              this.state.products.items,
              this.state.patientname,
              this.state.icd10,
              this.state.medicalaidname,
              this.state.medicalaidnumber,
              new Date(this.state.date).toDateString(),
              this.state.invoicenumber,
              this.state.totalvalue
            )}
            fileName="receipt.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download Receipt"
            }
          </PDFDownloadLink>
        </Button>
      </div>
    );
  }
}

export default IndividualTransaction;
