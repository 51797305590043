import React from "react";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

class AddProduct extends React.Component {
  constructor() {
    super();
    this.state = {
      brand: "",
      product: "",
      costperunit: 0,
      sellingpriceperunit: 0
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  render() {
    return (
      <div  style={{margin: 2, marginTop: 10}} >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.statusbar}
        autoHideDuration={3000}
        onClose={() => this.setState({ statusbar: false })}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{this.state.statusbartext}</span>}
      />
        <TextField
          style={{ width: 300,margin: 2 }}
          id="outlined-name"
          label="Brand"
          margin="normal"
          value={this.state.brand}
          onChange={change => this.setState({ brand: change.target.value })}
          variant="outlined"
        />
        <TextField
          style={{ width: 400,margin: 2 }}
          id="outlined-name"
          label="Product name"
          margin="normal"
          value={this.state.product}
          onChange={change => this.setState({ product: change.target.value })}
          variant="outlined"
        />
        <TextField
          style={{ width: 200 ,margin: 2}}
          id="outlined-name"
          label="Cost price / Unit"
          margin="normal"
          value={this.state.costperunit}
          onChange={change =>
            this.setState({ costperunit: change.target.value })
          }
          variant="outlined"
        />
        <TextField
          style={{ width: 200 ,margin: 2}}
          id="outlined-name"
          label="Selling price / Unit"
          margin="normal"
          value={this.state.sellingpriceperunit}
          onChange={change =>
            this.setState({ sellingpriceperunit: change.target.value })
          }
          variant="outlined"
        />
        <br />
        <Button  style={{margin: 2}}
          variant="contained"
          color="primary"
          onClick={() => {
            Utils.addproduct({
              sellingpriceperunit: this.state.sellingpriceperunit,
              costperunit: this.state.costperunit,
              product: this.state.product,
              brand: this.state.brand
            });
            this.doSnackBar("Saved")
            this.setState({
              brand: "",
              product: "",
              costperunit: 0,
              sellingpriceperunit: 0
            });
          }}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default AddProduct;
