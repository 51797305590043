/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDrhkalanTransactions = /* GraphQL */ `
  mutation CreateDrhkalanTransactions(
    $input: CreateDRHKALANTransactionsInput!
    $condition: ModelDRHKALANTransactionsConditionInput
  ) {
    createDRHKALANTransactions(input: $input, condition: $condition) {
      id
      patientname
      date
      icd10
      transactionnumber
      transactiontype
      invoicenumber
      receiptnumber
      medicalaidname
      medicalaidnumber
      totalvalue
      products {
        items {
          id
          brand
          product
          quantity
          icd10
          procedure
          costperunit
          sellingpriceperunit
          costtotal
          sellingpricetotal
        }
        nextToken
      }
    }
  }
`;
export const updateDrhkalanTransactions = /* GraphQL */ `
  mutation UpdateDrhkalanTransactions(
    $input: UpdateDRHKALANTransactionsInput!
    $condition: ModelDRHKALANTransactionsConditionInput
  ) {
    updateDRHKALANTransactions(input: $input, condition: $condition) {
      id
      patientname
      date
      icd10
      transactionnumber
      transactiontype
      invoicenumber
      receiptnumber
      medicalaidname
      medicalaidnumber
      totalvalue
      products {
        items {
          id
          brand
          product
          quantity
          icd10
          procedure
          costperunit
          sellingpriceperunit
          costtotal
          sellingpricetotal
        }
        nextToken
      }
    }
  }
`;
export const deleteDrhkalanTransactions = /* GraphQL */ `
  mutation DeleteDrhkalanTransactions(
    $input: DeleteDRHKALANTransactionsInput!
    $condition: ModelDRHKALANTransactionsConditionInput
  ) {
    deleteDRHKALANTransactions(input: $input, condition: $condition) {
      id
      patientname
      date
      icd10
      transactionnumber
      transactiontype
      invoicenumber
      receiptnumber
      medicalaidname
      medicalaidnumber
      totalvalue
      products {
        items {
          id
          brand
          product
          quantity
          icd10
          procedure
          costperunit
          sellingpriceperunit
          costtotal
          sellingpricetotal
        }
        nextToken
      }
    }
  }
`;
export const createDrhkalanProducts = /* GraphQL */ `
  mutation CreateDrhkalanProducts(
    $input: CreateDRHKALANProductsInput!
    $condition: ModelDRHKALANProductsConditionInput
  ) {
    createDRHKALANProducts(input: $input, condition: $condition) {
      id
      brand
      product
      quantity
      icd10
      procedure
      costperunit
      sellingpriceperunit
      costtotal
      sellingpricetotal
      transactions {
        id
        patientname
        date
        icd10
        transactionnumber
        transactiontype
        invoicenumber
        receiptnumber
        medicalaidname
        medicalaidnumber
        totalvalue
        products {
          nextToken
        }
      }
    }
  }
`;
export const updateDrhkalanProducts = /* GraphQL */ `
  mutation UpdateDrhkalanProducts(
    $input: UpdateDRHKALANProductsInput!
    $condition: ModelDRHKALANProductsConditionInput
  ) {
    updateDRHKALANProducts(input: $input, condition: $condition) {
      id
      brand
      product
      quantity
      icd10
      procedure
      costperunit
      sellingpriceperunit
      costtotal
      sellingpricetotal
      transactions {
        id
        patientname
        date
        icd10
        transactionnumber
        transactiontype
        invoicenumber
        receiptnumber
        medicalaidname
        medicalaidnumber
        totalvalue
        products {
          nextToken
        }
      }
    }
  }
`;
export const deleteDrhkalanProducts = /* GraphQL */ `
  mutation DeleteDrhkalanProducts(
    $input: DeleteDRHKALANProductsInput!
    $condition: ModelDRHKALANProductsConditionInput
  ) {
    deleteDRHKALANProducts(input: $input, condition: $condition) {
      id
      brand
      product
      quantity
      icd10
      procedure
      costperunit
      sellingpriceperunit
      costtotal
      sellingpricetotal
      transactions {
        id
        patientname
        date
        icd10
        transactionnumber
        transactiontype
        invoicenumber
        receiptnumber
        medicalaidname
        medicalaidnumber
        totalvalue
        products {
          nextToken
        }
      }
    }
  }
`;
export const createDrhkalanPriceList = /* GraphQL */ `
  mutation CreateDrhkalanPriceList(
    $input: CreateDRHKALANPriceListInput!
    $condition: ModelDRHKALANPriceListConditionInput
  ) {
    createDRHKALANPriceList(input: $input, condition: $condition) {
      id
      brand
      product
      costperunit
      sellingpriceperunit
    }
  }
`;
export const updateDrhkalanPriceList = /* GraphQL */ `
  mutation UpdateDrhkalanPriceList(
    $input: UpdateDRHKALANPriceListInput!
    $condition: ModelDRHKALANPriceListConditionInput
  ) {
    updateDRHKALANPriceList(input: $input, condition: $condition) {
      id
      brand
      product
      costperunit
      sellingpriceperunit
    }
  }
`;
export const deleteDrhkalanPriceList = /* GraphQL */ `
  mutation DeleteDrhkalanPriceList(
    $input: DeleteDRHKALANPriceListInput!
    $condition: ModelDRHKALANPriceListConditionInput
  ) {
    deleteDRHKALANPriceList(input: $input, condition: $condition) {
      id
      brand
      product
      costperunit
      sellingpriceperunit
    }
  }
`;
export const createDrhkalanTransactionnumber = /* GraphQL */ `
  mutation CreateDrhkalanTransactionnumber(
    $input: CreateDRHKALANTransactionnumberInput!
    $condition: ModelDRHKALANTransactionnumberConditionInput
  ) {
    createDRHKALANTransactionnumber(input: $input, condition: $condition) {
      id
      transactionnumber
    }
  }
`;
export const updateDrhkalanTransactionnumber = /* GraphQL */ `
  mutation UpdateDrhkalanTransactionnumber(
    $input: UpdateDRHKALANTransactionnumberInput!
    $condition: ModelDRHKALANTransactionnumberConditionInput
  ) {
    updateDRHKALANTransactionnumber(input: $input, condition: $condition) {
      id
      transactionnumber
    }
  }
`;
export const deleteDrhkalanTransactionnumber = /* GraphQL */ `
  mutation DeleteDrhkalanTransactionnumber(
    $input: DeleteDRHKALANTransactionnumberInput!
    $condition: ModelDRHKALANTransactionnumberConditionInput
  ) {
    deleteDRHKALANTransactionnumber(input: $input, condition: $condition) {
      id
      transactionnumber
    }
  }
`;
