import React from "react";
import "./App.css";
import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';
import Amplify,{Auth} from "aws-amplify";
import aws_exports from "./aws-exports"; // if you are using Amplify CLI
import AddProduct from "./addproduct";
import CreateInvoice from "./createinvoice";
import ViewProduct from "./viewproduct";
import Payments from "./payments";
import UpdatePriceList from "./updatepricelist";
import IndividualTransaction from "./individualtransaction";
import IndividualTransactionR from "./individualtransactionR";
import AddStockToInventory from "./addstock";
import CreditNote from "./creditnote";
import Header from "./header";

import Reports from "./reports";
import Instructions from "./instructions";
import { BrowserRouter as Router, Route } from "react-router-dom";

Amplify.configure(aws_exports);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "instructions",
      trx: {},
      pages: [
        {
          title: "Instructions",
          location: "/"
        },
        { title: "Add product", location: "/addproduct" },
        { title: "Create Invoice", location: "/createinvoice" },
        { title: "View Products", location: "/viewproduct" },
        { title: "Payments", location: "/payments" },
        { title: "Update Price List", location: "/updatepricelist" },
        { title: "Add Stock To Inventory", location: "/addstocktoinventory" },
        { title: "Reports", location: "/reports" },
          { title: "Logout", location: "/"}
      ]
    };
  }

  render() {
    if (this.props.authState === "signedIn")
      return (
        <div>
          <Router>
            <Header pages={this.state.pages}   onLogout={()=>Auth.signOut()} />
            <Route path="/" exact>
              <Instructions />
            </Route>
            <Route path="/addproduct">
              <AddProduct />
            </Route>
            <Route path="/createinvoice">
              <CreateInvoice />
            </Route>
            <Route path="/viewproduct">
              <ViewProduct />
            </Route>
            <Route path="/payments">
              <Payments />
            </Route>
            <Route path="/updatepricelist">
              <UpdatePriceList />
            </Route>
            <Route path="/individualtransaction" component={IndividualTransaction}/>
<Route path="/individualtransactionr" component={IndividualTransactionR}/>

            <Route path="/addstocktoinventory">
              <AddStockToInventory />
            </Route>
            <Route path="/creditnote" component={CreditNote}/>
            
            <Route path="/reports">
              <Reports />
            </Route>
          </Router>
        </div>
      );
    else return <div></div>;
  }
}

export default withAuthenticator(App, { includeGreetings: false });
