import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
 import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink
} from "@react-pdf/renderer";

// import OutlinedInput from "@material-ui/core/OutlinedInput";

class CreditNote extends React.Component {
  makedoc(
    itemsfull,
    patient,
    icd,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber
  ) {
    const TextArrayMapper = () => {

      var items = []
      itemsfull.forEach(function(e){
        if (e.changeqty != null && e.changeqty>0) items.push(e)
      })
      return items.map((item, index) => {
        return (
          <View
            key={index}
            style={{ flexDirection: "row", flexWrap: "wrap", marginBottom: 10 }}
          >
            <View
              style={{
                flexDirection: "column",
                maxWidth: "30%",
                minWidth: "30%"
              }}
            >
              <Text style={{ fontSize: 12 }}>{item.brand}</Text>
              <Text style={{ fontSize: 10 }}>{item.product}</Text>
            </View>
            <Text style={{ fontSize: 12, maxWidth: "10%", minWidth: "10%" }}>
              {item.icd10}
            </Text>
            <Text style={{ fontSize: 10, maxWidth: "15%", minWidth: "15%" }}>
              {item.procedure}
            </Text>
            <Text
              style={{
                fontSize: 12,
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "right"
              }}
            >
              {Number(item.changeqty)}
            </Text>


            <Text
              style={{
                fontSize: 12,
                maxWidth: "30%",
                minWidth: "30%",
                textAlign: "right"
              }}
            >
              {Utils.formatTextToCurrency(item.creditvalue)}
            </Text>
          </View>
        );
      });
    };

    function getTotalCredit(itemsfull){
      var total = 0
      itemsfull.forEach(function (e){
        if (e.changeqty != null) total+= e.creditvalue
      })

      return total
    }

    return (
      <Document>
        <Page
          size="A4"
          style={{
            flexDirection: "row",
            backgroundColor: "#FFF"
          }}
        >
          <View
            style={{
              margin: 10,
              padding: 10,
              width: "100%"
            }}
          >
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 10
              }}
            >
            <Text style={{ fontSize: 14 }}>DR HEMA KALAN INC</Text>
            <Text style={{ fontSize: 14 }}>VAT: 4600293072</Text>
            </View>
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 10
              }}
            >
              <Text style={{ fontSize: 14 }}>MBBCH (Wits)</Text>
              <Text style={{ fontSize: 14 }}>PR: 012 6012 HSA : 40101</Text>
              <Text style={{ fontSize: 14 }}>
                99 Lenchen Avenue North, Centurion, 0157
              </Text>
              <Text style={{ fontSize: 14 }}>
                Tel: 0126632732 Email: info@drhemakalan.com
              </Text>
            </View>
            <View
              style={{
                alignContent: "center",
                alignItems: "center",
                marginBottom: 30
              }}
            >
              <Text>CREDIT NOTE</Text>
            </View>
            <View
              style={{
                marginBottom: 10
              }}
            >
              <Text style={{ fontSize: 14 }}>Date: {date}</Text>
              <Text style={{ fontSize: 14 }}>Patient: {patient}</Text>
              <Text style={{ fontSize: 14 }}>
                Medical Aid Name and Number: {medicalaidname} {medicalaidnumber}
              </Text>
              <Text style={{ fontSize: 14 }}>ICD 10: {icd}</Text>
              <Text style={{ fontSize: 14 }}>
                Credit note number: {invoicenumber}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: 5
              }}
            >
              <Text style={{ fontSize: 12, maxWidth: "30%", minWidth: "30%" }}>
                Product Description
              </Text>
              <Text style={{ fontSize: 12, maxWidth: "10%", minWidth: "10%" }}>
                ICD10
              </Text>
              <Text style={{ fontSize: 12, maxWidth: "15%", minWidth: "15%" }}>
                Procedure
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "10%",
                  minWidth: "10%",
                  textAlign: "right"
                }}
              >
                Quantity
              </Text>


              <Text
                style={{
                  fontSize: 12,
                  maxWidth: "30%",
                  minWidth: "30%",
                  textAlign: "right"
                }}
              >
                Total
              </Text>
            </View>
            <TextArrayMapper />
              <Text
                style={{ fontSize: 14, textAlign: "right", marginRight: "5%" }}
              >
                VAT:{" "}
                {Utils.formatTextToCurrency((getTotalCredit(itemsfull)*(1-(1/1.15))).toFixed(2))}
              </Text>
            <Text
              style={{ fontSize: 14, textAlign: "right", marginRight: "5%" }}
            >
              Total Credit:{" "}
              {Utils.formatTextToCurrency(getTotalCredit(itemsfull).toFixed(2))}
            </Text>
          </View>
        </Page>
      </Document>
    );
  }

  constructor(props) {
    super(props);
      this.state = props.location.state.trx;
    // this.state = {
    //   docnumber: props.trx.invoicenumber,
    //   transaction: props.trx.transactionnumber,
    //   status: "",
    //   items: props.trx.products.items,
    //   patientname: props.trx.patientname,
    //   icd10: props.trx.icd10,
    //   medicalaidname: props.trx.medicalaidname,
    //   medicalaidnumber: props.trx.medicalaidnumber,
    //   date: new Date(props.trx.date).toDateString(),
    //   invoicenumber: props.trx.invoicenumber,
    //   receiptnumber: props.trx.receiptnumber,
    //   disableinvoice: true,
    //   disablereceipt: true,
    //   totalvalue: props.trx.totalvalue,
    //   id:props.trx.id
    // };
  }

  changeState(index, field, value) {
    var temp = this.state.products.items;
    temp[index][field] = value;
    if ((field === "changeqty") & (value > temp[index].quantity))
      temp[index].changeqty = temp[index].quantity;
    temp[index].creditvalue =
      temp[index].changeqty * temp[index].sellingpriceperunit;
    this.setState({ items: temp });
  }
  changeStateNoPrice(index, field, value) {
    var temp = this.state.products.items;
    temp[index][field] = value;

    this.setState({ items: temp });
  }

  maketablerows(inputitems) {
    return inputitems.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell>{item.brand}</TableCell>
          <TableCell>{item.product}</TableCell>
          <TableCell>{item.quantity}</TableCell>
          <TableCell>{item.sellingpriceperunit}</TableCell>
          <TableCell>
            <div>
              <TextField
                id="outlined-name"
                label="Credit quantity"
                margin="normal"
                value={item.changeqty || 0}
                onChange={change =>
                  this.changeState(
                    index,
                    "changeqty",
                    Number(change.target.value)
                  )
                }
                variant="outlined"
              />
            </div>
          </TableCell>
          <TableCell>
            <div>
              <TextField
                id="outlined-name"
                label="Credit value"
                margin="normal"
                value={item.creditvalue || 0}
                onChange={change =>
                  this.changeStateNoPrice(
                    index,
                    "creditvalue",
                    Number(change.target.value)
                  )
                }
                variant="outlined"
              />
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }

  async saveCreditNote(
    items,
    patientname,
    icd10,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber
  ) {
    this.setState({ status: "Saving..." });
    //console.log(medicalaidname);
    let saver = await Utils.saveCreditNote(
      items,
      patientname,
      icd10,
      medicalaidname,
      medicalaidnumber,
      date,
      invoicenumber
    );
    if (saver) {

      this.setState({
        status: "Saved",

      });
    }
  }

  render() {

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Brand</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Selling price</TableCell>
              <TableCell>Credit quantity</TableCell>
              <TableCell>Credit value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.maketablerows(this.state.products.items || null)}</TableBody>
        </Table>
        <Button style={{ margin: 2 }} variant="contained" color="primary">
          <PDFDownloadLink
            style={{ color: "#fff", textDecorationLine: "none" }}
            document={this.makedoc(
              this.state.products.items,
              this.state.patientname,
              this.state.icd10,
              this.state.medicalaidname,
              this.state.medicalaidnumber,
                new Date(this.state.date).toDateString() ,
              this.state.invoicenumber
            )}
            fileName="creditnote.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Preview credit note"
            }
          </PDFDownloadLink>
        </Button>
        <Button
          style={{ margin: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.saveCreditNote(
              this.state.products.items,
              this.state.patientname,
              this.state.icd10,
              this.state.medicalaidname,
              this.state.medicalaidnumber,
              new Date(Date.now()).toDateString(),
              this.state.invoicenumber
            );
          }}
        >
          Save credit note
        </Button>
  {this.state.status}
      </div>
    );
  }
}
export default CreditNote;
