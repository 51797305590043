import React from "react";
// import TextField from "@material-ui/core/TextField";
import Utils from "./utils";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";

// import OutlinedInput from "@material-ui/core/OutlinedInput";
import TableRow from "@material-ui/core/TableRow";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      item: "",
      month: 1,
      year: 2019,
      patientname: ""
    };
  }
  async componentDidMount() {

    var today = new Date(Date.now());
    var month = today.getMonth() + 1;
    var year = today.getFullYear();
    //     console.log(today)
    // console.log(month)
    var alltrx = await Utils.getAllSales(year, month);
    this.setState({ transactions: alltrx, month: month, year: year });
  }

async getPatient(patientname){
    var alltrx = await Utils.getPatient(patientname);
    this.setState({ transactions: alltrx });
}

  async addnextmonthoftransactions() {
    var month = this.state.month - 1;
    var year = this.state.year;
    if (month === 0) {
      year -= 1;
      month = 12;
    }
    var alltrx = await Utils.getAllSales(year, month);
    // console.log(alltrx.length)
    var oldtrx = this.state.transactions;
    alltrx.forEach(function(e) {
      oldtrx.push(e);
    });
    this.setState({ transactions: oldtrx, month: month, year: year });
  }

  async makePaid(input) {
    let paid = await Utils.makePaid(input);
    if (paid) {
      var oldtrx = this.state.transactions;
      oldtrx.forEach(function(e) {
        if (e.invoicenumber === input.invoicenumber)
          e.receiptnumber = e.invoicenumber;
      });
      this.setState({ transactions: oldtrx });
    }
  }

  makeReceiptButton(input) {
    if (input.receiptnumber === null)
      return (
        <Button
          style={{ margin: 2 }}
          variant="contained"
          disabled={this.checkDisabledForCreditNote(input.transactiontype)}
          color="primary"
          onClick={() => {
            this.makePaid(input);
          }}
        >
          Paid, get receipt
        </Button>
      );
    else
      return (
        <div>
          {input.receiptnumber}{" "}
          <Button
            style={{ margin: 2 }}
            variant="contained"
            color="primary"
            disabled={this.checkDisabledForCreditNote(input.transactiontype)}
            onClick={() => {
              this.getTransactionR(input.id);
            }}
          >
            Generate Receipt
          </Button>
        </div>
      );
  }

  async getTransactionR(id) {
    let trx = await Utils.getTransactionByID(id);
  this.props.history.push({pathname:"/individualtransactionr",state:{trx:trx}});
  }

  async getTransaction(id) {
    let trx = await Utils.getTransactionByID(id);
    this.props.history.push({pathname:"/individualtransaction",state:{trx:trx}});
  }

  async getTransactionCN(id) {
    let trx = await Utils.getTransactionByID(id);
    // console.log(trx)
    this.props.history.push({pathname:"/creditnote",state:{trx:trx}});
  }

  checkDisabledForCreditNote(input) {
    if (input === "Sale") return false;
    else return true;
  }

  maketablerows(inputitems) {
    return inputitems.map((item, index) => {
      return (
        <TableRow key={item.id}>
          <TableCell>{item.patientname}</TableCell>
          <TableCell>{new Date(item.date).toDateString()}</TableCell>
          <TableCell>
            {Utils.formatTextToCurrency(item.totalvalue.toFixed(2))}
          </TableCell>
          <TableCell>{item.transactiontype}</TableCell>
          <TableCell>{item.products.items.map((a,i)=><div key={i}>{a.product}</div>)}</TableCell>
          <TableCell>
            {item.invoicenumber}{" "}
            <Button
              style={{ margin: 2 }}
              disabled={this.checkDisabledForCreditNote(item.transactiontype)}
              variant="contained"
              color="primary"
              onClick={() => {
                this.getTransaction(item.id);
              }}
            >
              Generate Invoice
            </Button>
            <Button
              style={{ margin: 2 }}
              variant="contained"
              disabled={this.checkDisabledForCreditNote(item.transactiontype)}
              color="primary"
              onClick={() => {
                this.getTransactionCN(item.id);
              }}
            >
              Make new credit Note
            </Button>
          </TableCell>
          <TableCell>{this.makeReceiptButton(item)}</TableCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <div>
        <div style={{ margin: 10 ,
          display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

        }}>
          <TextField
            style={{ width: 300 }}
            id="outlined-name"
            label="Patient name"
            margin="normal"
            value={this.state.patientname}
            onChange={change =>
              this.setState({ patientname: change.target.value })
            }
            variant="outlined"
          />
          <Button
            style={{
              margin: 2,

            }}
            variant="contained"
            color="primary"
            onClick={() => {this.getPatient(this.state.patientname)}}
          >
            Search by name
          </Button>
          Case sensitive. So search for <div style={{marginLeft: 4,textDecorationLine: 'underline'}}>H</div>ema or search for <div style={{marginLeft: 4,textDecorationLine: 'underline'}}>ema</div>.
          <Button
            style={{
              margin: 2,

            }}
            variant="contained"
            color="primary"
            onClick={() => {this.componentDidMount()}}
          >
            Reset page
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Transaction type</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Receipt Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.maketablerows(this.state.transactions || null)}
          </TableBody>
        </Table>
        <br />
        <Button onClick={() => this.addnextmonthoftransactions()}>
          Load previous month
        </Button>
      </div>
    );
  }
}

export default withRouter(Payments);
