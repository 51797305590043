import { graphqlOperation, API } from "aws-amplify";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";

const Utils = {
  async addproduct(input) {
    await API.graphql(
      graphqlOperation(mutations.createDrhkalanPriceList, {
        input: {
          brand: input.brand,
          product: input.product,
          costperunit: input.costperunit,
          sellingpriceperunit: input.sellingpriceperunit,
        },
      })
    );
  },

  async getPriceList() {
    let priceList = await API.graphql(
      graphqlOperation(queries.listDrhkalanPriceLists, { limit: 10000 })
    );
    priceList.data.listDRHKALANPriceLists.items.sort(function (a, b) {
      var abrand = a.brand;
      var bbrand = b.brand;
      var aproduct = a.product;
      var bproduct = b.product;
      if (abrand === bbrand) {
        return aproduct < bproduct ? -1 : aproduct > bproduct ? 1 : 0;
      } else {
        return abrand < bbrand ? -1 : 1;
      }
    });
    return priceList.data.listDRHKALANPriceLists.items;
  },

  formatTextToCurrency(input) {
    const n = String(input),
      p = n.indexOf(".");
    var result =
      "R " +
      n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
        p < 0 || i < p ? `${m} ` : m
      );
    if (result.indexOf(".") === -1) result += ".00";
    else if (result.indexOf(".") === result.length - 1) result += "0";
    return result;
  },

  getPrices(pricelist, brand, product) {
    var costperunit,
      sellingpriceperunit = 0;
    pricelist.forEach(function (e) {
      if (e.brand === brand && e.product === product) {
        costperunit = e.costperunit;
        sellingpriceperunit = e.sellingpriceperunit;
      }
    });
    return { costperunit, sellingpriceperunit };
  },

  getUniqueBrands(input) {
    var allbrands = [];
    input.forEach(function (e) {
      allbrands.push(e.brand);
    });
    let s = new Set(allbrands);
    let it = s.values();
    var output = [];
    output.push({ label: "" });

    Array.from(it).forEach(function (a) {
      output.push({ label: a });
    });
    return output;
  },

  getUniqueProductByBrand(input, brand) {
    var allbrands = [];
    input.forEach(function (e) {
      if (e.brand === brand) allbrands.push(e.product);
    });
    let s = new Set(allbrands);
    let it = s.values();
    var output = [];
    output.push({ label: "" });
    Array.from(it).forEach(function (a) {
      output.push({ label: a });
    });
    return output;
  },

  calcTotal(input) {
    var total = 0;
    input.forEach(function (e) {
      total += Number(e.sellingpricetotal);
    });
    return total;
  },

  async saveProduct(item) {
    if (item.icd10 === "") item.icd10 = " ";
    if (item.procedure === "") item.procedure = " ";
    let result = await API.graphql(
      graphqlOperation(mutations.createDrhkalanProducts, {
        input: {
          brand: item.brand,
          product: item.product,
          quantity: -item.quantity,
          icd10: item.icd10 || "",
          procedure: item.procedure || "",
          costperunit: item.costperunit,
          sellingpriceperunit: item.sellingpriceperunit,
          costtotal: -Number(item.costtotal),
          sellingpricetotal: Number(item.sellingpricetotal),
          dRHKALANProductsTransactionsId: item.trx,
        },
      })
    );
    // console.log(result)
    return result;
  },

  async saveProductForStockAdjustment(item) {
    let result = await API.graphql(
      graphqlOperation(mutations.createDrhkalanProducts, {
        input: {
          brand: item.brand,
          product: item.product,
          quantity: item.quantity,
          costperunit: item.costperunit,
          costtotal: Number(item.costtotal),
          dRHKALANProductsTransactionsId: item.trx,
        },
      })
    );
    return result;
  },

  async saveInvoice(
    items,
    patientname,
    icd10,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber
  ) {
    if (patientname === "") patientname = " ";
    if (icd10 === "") icd10 = " ";
    if (medicalaidname === "") medicalaidname = " ";
    if (medicalaidnumber === "") medicalaidnumber = " ";
    // let trnum = await this.getNewTransactionNumber();
    try {
      let invoice = await API.graphql(
        graphqlOperation(mutations.createDrhkalanTransactions, {
          input: {
            patientname: patientname || "",
            icd10: icd10 || "",
            medicalaidnumber: medicalaidnumber || "",
            medicalaidname: medicalaidname || "",
            date: new Date(Date.now()),
            transactionnumber: invoicenumber,
            transactiontype: "Sale",
            invoicenumber: invoicenumber,
            totalvalue: this.calcTotal(items),
          },
        })
      );
      items.forEach(function (e) {
        e.trx = invoice.data.createDRHKALANTransactions.id;
      });
      this.updatetransactionnumber();
      const promises = items.map(this.saveProduct);
      let results = await Promise.all(promises);
      return results;
    } catch (err) {
      console.log(err);
    }
  },

  async saveCreditNote(
    itemsfull,
    patientname,
    icd10,
    medicalaidname,
    medicalaidnumber,
    date,
    invoicenumber
  ) {
    if (patientname === "") patientname = " ";
    if (icd10 === "") icd10 = " ";
    if (medicalaidname === "") medicalaidname = " ";
    if (medicalaidnumber === "") medicalaidnumber = " ";
    var items = [];
    var totalnew = 0;
    //console.log(itemsfull)
    itemsfull.forEach(function (e) {
      if (e.changeqty != null && e.changeqty > 0) {
        //console.log(e.changeqty)
        e.quantity = -e.changeqty;
        e.costtotal = e.changeqty * e.costperunit;
        e.sellingpricetotal = -e.creditvalue;
        totalnew += -e.creditvalue;
        items.push(e);
      }
    });

    let trnum = await this.gettransactionnumber();
    let invoice = await API.graphql(
      graphqlOperation(mutations.createDrhkalanTransactions, {
        input: {
          patientname: patientname || "",
          icd10: icd10 || "",
          medicalaidnumber: medicalaidnumber || "",
          medicalaidname: medicalaidname || "",
          date: new Date(Date.now()),
          transactionnumber: trnum + 1,
          transactiontype: "Credit note",
          invoicenumber: invoicenumber,
          totalvalue: totalnew,
        },
      })
    );
    // console.log(invoice)
    items.forEach(function (e) {
      e.trx = invoice.data.createDRHKALANTransactions.id;
    });
    this.updatetransactionnumber();
    // console.log(items)
    const promises = items.map(this.saveProduct);
    let results = await Promise.all(promises);
    return results;
  },

  async gettransactionnumber() {
    let s = await API.graphql(
      graphqlOperation(queries.getDrhkalanTransactionnumber, {
        id: "68f29b91-7c3d-4a19-8283-8aa3eb69eaa0",
      })
    );
    return s.data.getDRHKALANTransactionnumber.transactionnumber;
  },
  async updatetransactionnumber() {
    let s = await API.graphql(
      graphqlOperation(queries.getDrhkalanTransactionnumber, {
        id: "68f29b91-7c3d-4a19-8283-8aa3eb69eaa0",
      })
    );
    var newvalue = s.data.getDRHKALANTransactionnumber.transactionnumber + 1;
    let j = await API.graphql(
      graphqlOperation(mutations.updateDrhkalanTransactionnumber, {
        input: {
          id: "68f29b91-7c3d-4a19-8283-8aa3eb69eaa0",
          transactionnumber: newvalue,
        },
      })
    );
    return j.data.updateDRHKALANTransactionnumber.transactionnumber;
  },

  async getNewInvoiceNumber() {
    const listTransactionss = `query ListTransactionss(
      $filter: ModelDRHKALANTransactionsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDRHKALANTransactionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          invoicenumber
        }
        nextToken
      }
    }
    `;
    let invoicenumbersOG = await API.graphql(
      graphqlOperation(listTransactionss, { limit: 999999 })
    );
    var max = 0;
    var invoicenumbers = [];
    // console.log(invoicenumbersOG.data.listTransactionss.items);
    invoicenumbersOG.data.listTransactionss.items.forEach(function (e) {
      if (!isNaN(parseFloat(e.invoicenumber))) {
        if (e.invoicenumber != null)
          invoicenumbers.push(Number(e.invoicenumber));
      }
    });

    // console.log(invoicenumbers);
    if (invoicenumbers.length > 0)
      invoicenumbers.forEach(function (e) {
        if (e > max) {
          console.log(e);
          max = e;
        }
      });
    // console.log(max + 1);
    return max + 1;
  },

  async getNewTransactionNumber() {
    const listTransactionss = `query ListTransactionss(
      $filter: ModelDRHKALANTransactionsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTransactionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          transactionnumber
        }
        nextToken
      }
    }
    `;
    let invoicenumbers = await API.graphql(
      graphqlOperation(listTransactionss, { limit: 9999999 })
    );
    var max = 0;
    if (invoicenumbers.data.listTransactionss.items.length > 0)
      invoicenumbers.data.listTransactionss.items.forEach(function (e) {
        if (e.transactionnumber > max) max = e.transactionnumber;
      });
    return Number(max) + 1;
  },

  async getAllTransactionNumbers(startdate, enddate) {
    // const listTransactionss = `query ListTransactionss(
    //   $filter: ModelDRHKALANTransactionsFilterInput
    //   $limit: Int
    //   $nextToken: String
    // ) {
    //   listDRHKALANTransactionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    //     items {
    //       id
    //     }
    //     nextToken
    //   }
    // }
    // `;
    var nextToken = null;
    var i2 = {};
    var invoicenumbers = await API.graphql(
      graphqlOperation(queries.listDrhkalanTransactionss, {
        limit: 9999,
        filter: {
          date: {
            ge: startdate,
            le: enddate,
          },
        },
      })
    );
    // console.log(invoicenumbers.data.listTransactionss.items.length)
    nextToken = invoicenumbers.data.listDRHKALANTransactionss.nextToken;
    while (nextToken !== null) {
      i2 = await API.graphql(
        graphqlOperation(queries.listDrhkalanTransactionss, {
          limit: 9999,
          filter: {
            date: {
              ge: startdate,
              le: enddate,
            },
          },
          nextToken: nextToken,
        })
      );
      nextToken = i2.data.listDRHKALANTransactionss.nextToken;
      i2.data.listDRHKALANTransactionss.items.forEach(function (e) {
        invoicenumbers.data.listDRHKALANTransactionss.items.push(e);
      });
      // console.log(invoicenumbers.data.listTransactionss.items.length)
    }
    return invoicenumbers.data.listDRHKALANTransactionss.items;
  },

  async getAllSales(year, month) {
    var newmonth = "";
    if (month < 10) newmonth = "0" + month;
    else newmonth = month;
    var nextmonth = month + 1;
    if (nextmonth === 13) nextmonth = 1;
    if (nextmonth < 10) nextmonth = "0" + nextmonth;
    var newyear = year;
    if (month === 12) newyear += 1;
    var nextToken = null;
    var i2 = {};

    var invoicenumbers = await API.graphql(
      graphqlOperation(queries.listDrhkalanTransactionss, {
        limit: 9999,
        filter: {
          transactiontype: { eq: "Sale" },
          date: {
            ge: year + "-" + newmonth + "-01",
            le: newyear + "-" + nextmonth + "-01",
          },
        },
      })
    );
    nextToken = invoicenumbers.data.listDRHKALANTransactionss.nextToken;
    while (nextToken !== null) {
      i2 = await API.graphql(
        graphqlOperation(queries.listDrhkalanTransactionss, {
          limit: 9999,
          filter: {
            transactiontype: { eq: "Sale" },
            date: {
              ge: year + "-" + newmonth + "-01",
              le: newyear + "-" + nextmonth + "-01",
            },
          },
          nextToken: nextToken,
        })
      );
      nextToken = i2.data.listDRHKALANTransactionss.nextToken;
      // console.log(i2.data.listTransactionss.items.length);
      // console.log(i2);
      i2.data.listDRHKALANTransactionss.items.forEach(function (e) {
        invoicenumbers.data.listDRHKALANTransactionss.items.push(e);
      });
    }

    var invoicenumbersCN = await API.graphql(
      graphqlOperation(queries.listDrhkalanTransactionss, {
        limit: 9999,
        filter: {
          transactiontype: { eq: "Credit note" },
          date: {
            ge: year + "-" + newmonth + "-01",
            le: newyear + "-" + nextmonth + "-01",
          },
        },
      })
    );
    invoicenumbersCN.data.listDRHKALANTransactionss.items.forEach(function (e) {
      invoicenumbers.data.listDRHKALANTransactionss.items.push(e);
    });
    // console.log(invoicenumbers.data.listTransactionss.items.length);
    nextToken = invoicenumbersCN.data.listDRHKALANTransactionss.nextToken;
    while (nextToken !== null) {
      i2 = await API.graphql(
        graphqlOperation(queries.listDrhkalanTransactionss, {
          limit: 9999,
          filter: {
            transactiontype: { eq: "Credit note" },
            date: {
              ge: year + "-" + newmonth + "-01",
              le: newyear + "-" + nextmonth + "-01",
            },
          },
          nextToken: nextToken,
        })
      );
      nextToken = i2.data.listDRHKALANTransactionss.nextToken;
      // console.log(i2.data.listTransactionss.items.length);
      // console.log(i2);
      i2.data.listDRHKALANTransactionss.items.forEach(function (e) {
        invoicenumbers.data.listDRHKALANTransactionss.items.push(e);
      });
    }

    function mycomparator(a, b) {
      return new Date(b.date) - new Date(a.date);
    }

    var results = invoicenumbers.data.listDRHKALANTransactionss.items.sort(
      mycomparator
    );

    return results;
  },

  async getPatient(patientname) {
    var nextToken = null;
    var i2 = {};
    var invoicenumbers = await API.graphql(
      graphqlOperation(queries.listDrhkalanTransactionss, {
        limit: 9999,
        filter: {
          patientname: { contains: patientname },
        },
      })
    );
    nextToken = invoicenumbers.data.listDRHKALANTransactionss.nextToken;
    while (nextToken !== null) {
      i2 = await API.graphql(
        graphqlOperation(queries.listDrhkalanTransactionss, {
          limit: 9999,
          filter: {
            patientname: { contains: patientname },
          },
          nextToken: nextToken,
        })
      );
      nextToken = i2.data.listDRHKALANTransactionss.nextToken;
      i2.data.listDRHKALANTransactionss.items.forEach(function (e) {
        invoicenumbers.data.listDRHKALANTransactionss.items.push(e);
      });
    }
    function mycomparator(a, b) {
      return new Date(b.date) - new Date(a.date);
    }

    var results = invoicenumbers.data.listDRHKALANTransactionss.items.sort(
      mycomparator
    );

    return results;
  },

  async getTransaction(invoicenumber) {
    let trx = await API.graphql(
      graphqlOperation(queries.listDrhkalanTransactionss, {
        filter: { invoicenumber: { eq: invoicenumber } },
      })
    );

    if (trx.data.listDRHKALANTransactionss.items.length > 0) {
      let doc = await API.graphql(
        graphqlOperation(queries.getDrhkalanTransactions, {
          id: trx.data.listDrhkalanTransactionss.items[0].id,
        })
      );
      // console.log(doc.data.getTransactions);
      return doc.data.getDRHKALANTransactions;
    } else return null;
  },

  async getReportingTransactionByID(id) {
    // console.log(id)
    let doc = await API.graphql(
      graphqlOperation(queries.getDrhkalanTransactions, {
        id: id,
      })
    );
    // console.log(doc.data.getTransactions)
    return doc.data.getDRHKALANTransactions;
  },

  async getTransactionByID(id) {
    let doc = await API.graphql(
      graphqlOperation(queries.getDrhkalanTransactions, {
        id: id,
        
      })
    );
    console.log(doc);
    // if (doc.data.getDRHKALANTransactions.products.nextToken !== null) {
    //   let d2 = await API.graphql(
    //     graphqlOperation(queries.getDrhkalanTransactions, {
    //       id: id,
    //       nextToken: doc.data.getDRHKALANTransactions.products.nextToken,
    //     })
    //   );
    //   console.log(d2);
    //   doc.data.getDRHKALANTransactions.products.items = doc.data.getDRHKALANTransactions.products.items.concat(
    //     d2.data.getDRHKALANTransactions.products.items
    //   );
    // }

    doc.data.getDRHKALANTransactions.products.items.forEach(function (e) {
      e.quantity = -e.quantity;
    });
    return doc.data.getDRHKALANTransactions;
  },

  async makePaid(input) {
    let invoice = await API.graphql(
      graphqlOperation(mutations.updateDrhkalanTransactions, {
        input: {
          id: input.id,
          receiptnumber: input.invoicenumber,
        },
      })
    );
    return invoice;
  },

  async saveNewStock(items) {
    let trnum = await this.gettransactionnumber();
    let invoice = await API.graphql(
      graphqlOperation(mutations.createDrhkalanTransactions, {
        input: {
          date: new Date(Date.now()),
          transactionnumber: trnum + 1,
          transactiontype: "StockAdjustment",
        },
      })
    );
    items.forEach(function (e) {
      e.trx = invoice.data.createDRHKALANTransactions.id;
    });
    this.updatetransactionnumber();
    const promises = items.map(this.saveProductForStockAdjustment);
    let results = await Promise.all(promises);
    return results;
  },

  async BULKsaveNewStock(item, transactionnumber, date) {
    try {
      let invoice = await API.graphql(
        graphqlOperation(mutations.createDrhkalanTransactions, {
          input: {
            date: date,
            transactionnumber: transactionnumber,
            transactiontype: "StockAdjustment",
          },
        })
      );
      item.trx = invoice.data.createDrhkalanTransactions.id;

      await this.saveProductForStockAdjustment(item);
      // if (r) console.log("success");
    } catch (e) {
      console.log(e);
    }
    //return results;
  },

  async BULKdeleterec(item) {
    let del = await API.graphql(
      graphqlOperation(mutations.deleteDrhkalanTransactions, {
        input: { id: item.id },
      })
    );
    return del;
  },

  async BULKdelete() {
    let trx = await API.graphql(
      graphqlOperation(queries.listDrhkalanTransactionss, { limit: 1000 })
    );
    const promises = trx.data.listDrhkalanTransactionss.items.map(
      this.BULKdeleterec
    );
    let results = await Promise.all(promises);
    return results;
  },
  async BULKdeleterecprod(item) {
    let del = await API.graphql(
      graphqlOperation(mutations.deleteDrhkalanProducts, {
        input: { id: item.id },
      })
    );
    return del;
  },

  async BULKdeleteprod() {
    let trx = await API.graphql(
      graphqlOperation(queries.listDrhkalanProductss, { limit: 1000 })
    );
    const promises = trx.data.listDrhkalanProductss.items.map(
      this.BULKdeleterecprod
    );
    let results = await Promise.all(promises);
    return results;
  },

  async BULKsaveNewInvoiceindividual(item) {
    //return await this.saveInvoice(item.items,item.patientname,"","","",item.date,item.invoicenumber )
    var total = 0;
    item.items.forEach(function (e) {
      total += Number(e.sellingpricetotal);
    });
    let invoice = await API.graphql(
      graphqlOperation(mutations.createDrhkalanTransactions, {
        input: {
          patientname: item.patientname,
          date: item.date,
          transactionnumber: item.invoicenumber,
          transactiontype: "Sale",
          invoicenumber: item.invoicenumber,
          totalvalue: Number(total),
        },
      })
    );
    item.items.forEach(function (e) {
      e.trx = invoice.data.createDrhkalanTransactions.id;
    });

    var items = item.items;
    var promises = items.map((item) => {
      API.graphql(
        graphqlOperation(mutations.createDrhkalanProducts, {
          input: {
            brand: item.brand,
            product: item.product,
            quantity: -item.quantity,
            costperunit: item.costperunit,
            sellingpriceperunit: item.sellingpriceperunit,
            costtotal: Number(item.costtotal),
            sellingpricetotal: Number(item.sellingpricetotal),
            productsTransactionsId: item.trx,
          },
        })
      );
      return true;
    });
    let results = await Promise.all(promises);
    return results;
    // items,
    // patientname,
    // icd10,
    // medicalaidname,
    // medicalaidnumber,
    // date,
    // invoicenumber
  },

  async BULKsaveNewInvoice(input) {
    var test = [];
    for (var i = 0; i < input.length; i++) {
      test.push(input[i]);
    }
    const promises = test.map(this.BULKsaveNewInvoiceindividual);
    let results = await Promise.all(promises);
    console.log("done");
    return results;
  },

  async getCurrentQuantity() {
    var qty = {};
    var nextToken = null;
    const listProductss = `query ListProductss(
      $filter: ModelDRHKALANProductsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDRHKALANProductss(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          brand
          product
          quantity
        }
        nextToken
      }
    }
    `;
    qty = await API.graphql(
      graphqlOperation(listProductss, {
        limit: 5000,
      })
    );
    nextToken = qty.data.listDRHKALANProductss.nextToken;
    while (nextToken !== null) {
      var looper = await API.graphql(
        graphqlOperation(listProductss, {
          limit: 5000,
          nextToken: nextToken,
        })
      );
      nextToken = looper.data.listDRHKALANProductss.nextToken;
      looper.data.listDRHKALANProductss.items.forEach(function (e) {
        qty.data.listDRHKALANProductss.items.push(e);
      });
    }
    return qty.data.listDRHKALANProductss.items;
  },

  matchItems(itemlist, qtylist) {
    itemlist.forEach(function (a) {
      a.quantity = 0;
      qtylist.forEach(function (b) {
        if ((a.brand === b.brand) & (a.product === b.product))
          a.quantity += b.quantity;
      });
    });
    return itemlist;
  },

  async getCurrentQuantityForBrandAndProduct(brand, product) {
    var qty = {};
    var nextToken = null;
    var result = 0;
    const listProductss = `query ListProductss(
      $filter: ModelDRHKALANProductsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDRHKALANProductss(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          brand
          product
          quantity
        }
        nextToken
      }
    }
    `;
    qty = await API.graphql(
      graphqlOperation(listProductss, {
        limit: 5000,
        filter: { brand: { eq: brand }, product: { eq: product } },
      })
    );
    nextToken = qty.data.listDRHKALANProductss.nextToken;
    while (nextToken !== null) {
      var looper = await API.graphql(
        graphqlOperation(listProductss, {
          limit: 5000,
          filter: { brand: { eq: brand }, product: { eq: product } },
          nextToken: nextToken,
        })
      );
      nextToken = looper.data.listDRHKALANProductss.nextToken;
      looper.data.listDRHKALANProductss.items.forEach(function (e) {
        qty.data.listDRHKALANProductss.items.push(e);
      });
    }
    qty.data.listDRHKALANProductss.items.forEach(function (e) {
      result += e.quantity;
    });
    return result;
  },

  async updatePL(input) {
    let update = await API.graphql(
      graphqlOperation(mutations.updateDrhkalanPriceList, {
        input: {
          id: input.id,
          costperunit: input.costperunit,
          sellingpriceperunit: input.sellingpriceperunit,
        },
      })
    );
    return update.data.updateDrhkalanPriceList;
  },
};
export default Utils;
